@import url(https://fonts.googleapis.com/css?family=Roboto);
#root {
  height: 100%; }

html,
body {
  height: 100%; }

body {
  background-color: white; }

.btn.btn-outline-primary {
  color: #0E48A4;
  border-color: #0E48A4; }
  .btn.btn-outline-primary:hover, .btn.btn-outline-primary:active {
    color: white;
    background-color: #0E48A4 !important;
    border-color: #0E48A4 !important; }

.btn.btn-success {
  border-color: #389636;
  background-color: #389636; }
  .btn.btn-success:hover, .btn.btn-success:active {
    background-color: #218838; }

.btn.btn-primary {
  border-color: #0E48A4;
  background-color: #0E48A4; }
  .btn.btn-primary:hover, .btn.btn-primary:active {
    background-color: #0062cc;
    border-color: #0062cc; }

.btn.btn-outline-warning.orange {
  color: #FB7F2B;
  border-color: #FB7F2B; }
  .btn.btn-outline-warning.orange:hover, .btn.btn-outline-warning.orange:active {
    color: white;
    background-color: #FB7F2B !important;
    border-color: #FB7F2B !important; }

.text-blue {
  color: #0E48A4; }

.bg-blue {
  background-color: #0E48A4; }

.text-gray {
  color: #808080; }

