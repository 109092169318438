$gray: #F0F0F0;
$gray-1: #808080;
$gray-3: #404040;
$gray-4: #666666;
$gray-5: #FAFAFA;
$gray-6: #707070;
$gray-7: #C3C3C3;
$gray-8: #F7F7F7;

$blue: #0E48A4;
$blue-2: #2e3192;
$blue-3: #0071BC;
$blue-4: rgba(0, 113, 188, 0.7);

$yellow: #FFF433;
$yellow-1: #FFD800;

$green: #389636;
$green-1: #00aa88;

$red: #CA0830;

$orange: #FB7F2B;

$light: #ffffff;
$light-2: #ececec;
