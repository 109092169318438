@import url("https://fonts.googleapis.com/css?family=Roboto");

@import "~material-design-icons/iconfont/material-icons.css";
@import "./colors.sass";

// $background: #f7f7f7
// $blue: #0060b2
// $royal: #0091DD
// $yellow: #FFD800
// $orange: #FF9901
// $light: #ffffff
// $gray: #707070
// $lightgray: #ececec
// $red: #e74a4a
// $green: #32AF06
// $warning: #ffc107
// $dark: #404040

// $gold: #DEAC04
// $golden: #FFF433
// $silver: #C3C3C3
// $bronze: #C17001

// $abcredblue: #0E48A4
// $abcredhomegreen: #389636
// $abcredhomeorange: #FB7F2B
// $abcredhomeblue: #29B7FC
// $abcredlightgreen: #BFF236
// $abcredlightgray: #ECECEC
// $abcredlightergray: #F5F5F5

// $calendarlightblue: #29B7FC
// $calendargreen: #389636
// $calendarorange: #FB7F2B

// $text: $gray

// $pageWidth: 1280px

// $font-normal: "Fira Sans", sans-serif
// $font-condensed: "Fira Sans", sans-serif

// $box-shadow: 0px 0px 20px -10px #000
// $solid-box-shadow: 0px 0px 10px -5px #000
// $light-box-shadow: 0 5px 15px -5px #ccc

// $downarrowsvg: '../../app/icons/baseline-expand_more-24px.svg'

#root {
    height: 100%; }

html,
body {
    height: 100%; }

body {
    background-color: white; }

.btn {
    &.btn-outline-primary {
        color: $blue;
        border-color: $blue;

        &:hover,
        &:active {
            color: white;
            background-color: $blue !important;
            border-color: $blue !important; } }

    &.btn-success {
        border-color: $green;
        background-color: $green;

        &:hover,
        &:active {
            background-color: #218838; } }

    &.btn-primary {
        border-color: $blue;
        background-color: $blue;

        &:hover,
        &:active {
            background-color: #0062cc;
            border-color: #0062cc; } }

    &.btn-outline-warning.orange {
        color: $orange;
        border-color: $orange;

        &:hover,
        &:active {
            color: white;
            background-color: $orange !important;
            border-color: $orange !important; } } }

.text-blue {
    color: $blue; }

.bg-blue {
    background-color: $blue; }

.text-gray {
    color: $gray-1; }
